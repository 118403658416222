import { IArrayResponse } from "core/models";
import { IInteraction } from "modules/Interaction/models";

export enum WebhookQuery {
  "webhook" = "webhook",
  "webhooks" = "webhooks",
}

export enum WebhookEvents {
  "connection_request.sent" = "connection_request.sent",
  "connection_request.accepted" = "connection_request.accepted",
  "connection_request.replied" = "connection_request.replied",
  "msg.sent" = "msg.sent",
  "msg.replied" = "msg.replied",
  "connected.data_update" = "connected.data_update",
}

export enum WebhookEventLabels {
  "connection_request.sent" = "Connection request sent",
  "connection_request.accepted" = "Connection request accepted",
  "connection_request.replied" = "Connection request replied",
  "msg.sent" = "Message sent",
  "msg.replied" = "Message replied",
  "connected.data_update" = "Contact info collected after connection",
}

export enum WebhookStatusLabels {
  "paused" = "Paused",
  "active" = "Active",
}

export enum WebhookStatusColors {
  "paused" = "warning",
  "active" = "success",
}

export interface IWebhookFormInput {
  name: string;
  event: WebhookEvents | null;
  endpoint: string;
  active: boolean;
}

export interface IWebhookFormValues {
  name: string;
  event: WebhookEvents;
  endpoint: string;
  account: number;
  active: boolean;
  campaign?: number;
}

export interface IWebhook extends IWebhookFormValues {
  id: number;
  created: string;
}

export interface IWebhooks extends IArrayResponse {
  results: IWebhook[];
}

export interface IWebhookResponse {
  event: {
    name: string;
    account_id: string;
    description: string;
    type: WebhookEvents;
  };
  interaction: IInteraction;
}
