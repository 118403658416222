import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import LoopIcon from "@mui/icons-material/Loop";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import { ICampaignSearch, ICampaignSearches } from "modules/Campaign/models";
import { cleanPersonDetail } from "modules/Person/utils";
import { createChipData, IStatusChipData } from "ui-kit/atoms/Chip";
import { ITableHead } from "ui-kit/components/Table";
import { STATIC_HEADS } from "../constants";
import {
  IColumnToRename,
  ISearch,
  ISearchExtendedFormInput,
  ISearchResult,
  ISearchSheetFieldsFormatValues,
  ISearchSheetFieldsFormValues,
  LinkedInSearchVariants,
  SearchMatchFilterStrategy,
  SearchResultStatus,
  SearchResultStatusMatchToVariant,
  SearchStatus,
  SearchStatusMatchToVariant,
  SearchStrategy,
  SearchTabIndex,
} from "../models";

const getSearchStatusChipData = (
  status: ISearch["status"]
): IStatusChipData => {
  switch (status) {
    case SearchStatusMatchToVariant.queue:
      return createChipData("warning", AccessTimeIcon, SearchStatus.queue);
    case SearchStatusMatchToVariant.processing:
      return createChipData("primary", LoopIcon, SearchStatus.processing);
    case SearchStatusMatchToVariant.processed:
      return createChipData("success", CheckIcon, SearchStatus.processed);
    case SearchStatusMatchToVariant.failed:
    default:
      return createChipData("error", ClearIcon, SearchStatus.failed);
  }
};

const getSearchResultStatusChipData = (
  status: ISearchResult["status"]
): IStatusChipData => {
  switch (status) {
    case SearchResultStatusMatchToVariant.queue:
      return createChipData(
        "warning",
        AccessTimeIcon,
        SearchResultStatus.queue
      );
    case SearchResultStatusMatchToVariant.processed:
      return createChipData("success", CheckIcon, SearchResultStatus.processed);
    case SearchResultStatusMatchToVariant.failed:
    default:
      return createChipData("error", ClearIcon, SearchResultStatus.failed);
  }
};

const getSchedulingChipData = (
  add_automatically: ICampaignSearch["add_automatically"]
): IStatusChipData =>
  add_automatically
    ? createChipData("secondary", AccessTimeIcon, "Automatic")
    : createChipData("warning", AccessTimeIcon, "Manual");

const ignoreKey = "Ignore this key";

const formatGoogleSheetData = (
  d: ISearchExtendedFormInput,
  search: ISearchSheetFieldsFormValues,
  accountId: number,
  campaignId: number | undefined
): ISearchSheetFieldsFormatValues => {
  const newData: ISearchSheetFieldsFormatValues = search.columns
    ? {
        name: search.searchName,
        strategy_data: {
          url: search.url,
          columns_to_rename: [] as IColumnToRename[],
        },
        strategy:
          SearchStrategy["inevitable.strategy.search.GoogleSheetsStrategy"],
        account: accountId,
        add_automatically: true,
        campaign: campaignId,
      }
    : {
        name: d.searchName,
        strategy_data: {
          url: d.url,
        },
      };

  const { column_to_rename } = d;
  if (column_to_rename && !isEmpty(column_to_rename)) {
    const columns_to_rename: IColumnToRename[] = [];
    Object.keys(column_to_rename).forEach((columnKey) => {
      const v = column_to_rename[columnKey];
      columns_to_rename.push({
        key_to_rename: columnKey,
        new_key_name: v,
        delete: v === ignoreKey,
      });
    });

    newData.strategy_data.columns_to_rename = columns_to_rename;
  }
  return newData;
};

const useCreateSearchStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.app.constants.dialog.campaignWidth,
    },
  },
  box: {
    padding: theme.spacing(6, 8, 10),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
    },
    position: "relative",
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.app.palette.shadow.primary}`,
    minHeight: 400,
  },
}));

const getSearchResultOnlyName = (
  search_result: ISearchResult | undefined
): string => {
  if (!search_result) return "";

  if (
    search_result.placeholders?.first_name ||
    search_result.placeholders?.last_name
  ) {
    const { first_name: firstName, last_name: lastName } =
      search_result.placeholders;
    let name = "";
    if (firstName) {
      name += firstName;
    }
    if (firstName && lastName) {
      name += " ";
    }
    if (lastName) {
      name += lastName;
    }

    return name;
  }

  return "";
};

const getSearchResultName = (
  search_result: ISearchResult | undefined
): string => {
  if (!search_result) return "";

  const searchResultName = getSearchResultOnlyName(search_result);

  if (!!searchResultName) {
    return searchResultName;
  }

  return search_result.profile_url;
};

const getSearchResultSubText = (
  props: ISearchResult["placeholders"] | undefined
): string | undefined => {
  if (!props) {
    return;
  }

  const { company_name, job_title, occupation } = props;

  if (job_title || company_name) {
    let text = "";
    if (job_title) {
      text += job_title;
    }
    if (job_title && company_name) {
      text += " ";
    }
    if (company_name) {
      text += company_name;
    }
    return text;
  }

  if (occupation) {
    return cleanPersonDetail(occupation);
  }
  return undefined;
};

const getCustomHeads = (heads: string[]): string[] => {
  return heads.filter((h) => !STATIC_HEADS.includes(h));
};

const getColumnsFromCampaignSearches = (items: ICampaignSearches) => {
  return compact(
    flatten(
      items.results.map(
        (campaignSearch) =>
          campaignSearch.search.strategy_data.columns_to_rename
      )
    )
  );
};

const generateHeads = (columns: IColumnToRename[] | undefined): string[] => {
  const prefixHeads = STATIC_HEADS[0]; // "name"
  const suffixHeads = STATIC_HEADS[1]; // "actions"
  // const ignoreColumns = ["first_name", "last_name"];
  if (!columns) {
    return [prefixHeads, suffixHeads];
  }

  const customHeads = columns
    .filter((column) => !column.delete)
    // .filter((column) => !ignoreColumns.includes(column.new_key_name))
    .map((column) => column.new_key_name);

  return [prefixHeads, ...uniq(customHeads), suffixHeads];
};

const firstHead = {
  percentage: 22,
  width: 225,
};
const lastHead = {
  percentage: 12,
  width: 140,
};
const remainingPercentage = 100 - firstHead.percentage - lastHead.percentage;
const validateCustomHeads = (h: number) => h < 5;

const calculateWidth = (
  id: string,
  index: number,
  total: number,
  customHeads: number
): number => {
  // If there are no custom heads, use the full width for 2 predefined
  if (customHeads === 0) {
    // Use small one for actions
    return index === 0 ? 100 - lastHead.percentage : lastHead.percentage;
  }

  if (validateCustomHeads(customHeads)) {
    if (index === 0) {
      return firstHead.percentage;
    }

    if (index === total - 1) {
      return lastHead.percentage;
    }

    return remainingPercentage / customHeads;
  }

  // If name
  if (index === 0) {
    return firstHead.width;
  }

  // If actions
  if (index === total - 1) {
    return lastHead.width;
  }

  // Make place for all custom
  const width = 220;
  return width;
};

const formatHeads = (heads: string[]): ITableHead[] => {
  const customHeads = heads.length - 2;
  return heads.map((id, index) => {
    return {
      id,
      label: id,
      width: calculateWidth(id, index, heads.length, customHeads),
      // width: ConfigConstant.PLACEHOLDER_INPUT_WIDTH,
      percentage: validateCustomHeads(customHeads),
    };
  });
};

const getLinkedSearchStrategy = (url: string) => {
  if (!url) return null;

  const str = url.replace(ConfigConstant.LINKEDIN_ROOT, "");
  const variant = str.split("/")?.[0] as LinkedInSearchVariants;

  switch (variant) {
    case LinkedInSearchVariants.search:
      return SearchMatchFilterStrategy.linkedin_search;

    case LinkedInSearchVariants.sales:
      return SearchMatchFilterStrategy.sales_nav;

    case LinkedInSearchVariants.talent:
      return SearchMatchFilterStrategy.recruiter;

    // case LinkedInSearchVariants.group:
    //   return SearchMatchFilterStrategy.group_search;

    default:
      return null;
  }
};

const getMaxAvailableSearchResultCount = (
  maxTotal: number | undefined,
  totalCount: number | undefined
): number => {
  if (!maxTotal && !!totalCount) {
    return totalCount;
  }

  if (!maxTotal || !totalCount) {
    return 0;
  }

  const count = Number(maxTotal) < Number(totalCount) ? maxTotal : totalCount;
  return count;
};

const LINKEDIN_SEARCH_VARIANTS = [
  SearchTabIndex.linkedin_search,
  // SearchTabIndex.group_search,
];

const isGoogleSheet = (strategy: ISearch["strategy"]) => {
  return strategy === SearchStrategy[SearchMatchFilterStrategy["google_sheet"]];
};

const isGoogleDocsUrl = (url: string): boolean => {
  // Regular expression pattern to match Google Docs URLs
  const pattern =
    /^https?:\/\/docs\.google\.com\/(?:document|presentation|spreadsheets)\/d\/([a-zA-Z0-9_-]+)/;

  // Check if the URL matches the pattern
  return pattern.test(url);
};

const SearchUtils = {
  useCreateSearchStyles,
  getSchedulingChipData,
  getSearchStatusChipData,
  formatGoogleSheetData,
  getSearchResultStatusChipData,
  ignoreKey,
  getSearchResultSubText,
  getSearchResultName,
  getSearchResultOnlyName,
  LINKEDIN_SEARCH_VARIANTS,
  generateHeads,
  formatHeads,
  getCustomHeads,
  getLinkedSearchStrategy,
  isGoogleSheet,
  isGoogleDocsUrl,
  validateCustomHeads,
  getColumnsFromCampaignSearches,
  getMaxAvailableSearchResultCount,
};

export default SearchUtils;
