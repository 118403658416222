import CampaignService from "modules/Campaign/services";
import { getStartedCount } from "modules/Campaign/utils";
import React from "react";
import { useQuery } from "react-query";
import ProgressBar, {
  BaseProgressBarProps,
} from "ui-kit/components/ProgressBar";

interface CampaignProgressProps extends BaseProgressBarProps {
  campaignId: number;
}

const CampaignProgress = ({
  campaignId,
  ...rest
}: CampaignProgressProps): React.ReactElement => {
  const fetchCampaignStats = async () => {
    try {
      const { data } = await CampaignService.fetchCampaignStats([campaignId]);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataStats, isLoading: isLoadingStats } = useQuery(
    ["campaign-stats", campaignId],
    () => fetchCampaignStats(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return (
    <ProgressBar
      count={getStartedCount(campaignId, dataStats)}
      total={dataStats?.counts.all?.[campaignId] || 0}
      isLoading={isLoadingStats}
      failures={dataStats?.counts.failed?.[campaignId]}
      {...rest}
    />
  );
};

export default CampaignProgress;
